<template>
  <div class="index-box">
    <div class="index-img">
      <img src="@//img/index01.jpg" alt="" />
    </div>
    <!-- 免费获取 -->
    <div class="index-from-tob">
      <div class="title-name">限时免费领取小程序开发报价</div>
      <div class="index-tab">
        <div class="tab-item">
          <div>姓名 <span>*</span></div>
          <input type="text" placeholder="请留下您的姓名" />
        </div>
        <div class="tab-item">
          <div>电话 <span>*</span></div>
          <input type="text" placeholder="请留下您的电话" />
        </div>
      </div>
      <div class="from-but">
        <div class="button">立即获取</div>
      </div>
    </div>
    <div>
      <div class="title-name">产品中心</div>
      <div style="color: #999; font-size: 14px; margin: 15px">
        你可以及时了解公司以及公司产品的相关动态
      </div>
      <div class="index-img">
        <img src="@//img/index07.jpg" alt="" style="width: 80%" />
      </div>
    </div>

    <div class="index-img">
      <img src="@//img/index02.jpg" alt="" />
    </div>
    <div class="index-img">
      <img src="@//img/index03.jpg" alt="" />
    </div>
    <div>
      <div class="title-name">案例介绍</div>
      <div class="listCss">
        <div class="list-item" v-for="item in caseList" :key="item.id">
          <div class="item-img"><img :src="item.img" /></div>
          <div class="item-name"><span></span>{{ item.name }}</div>
          <div class="item-type">小程序开发</div>
          <div class="item-but">立即联系</div>
        </div>
      </div>
    </div>
    <div class="index-img">
      <img src="@//img/index04.jpg" alt="" />
    </div>
    <div class="index-img">
      <img src="@//img/index05.jpg" alt="" />
    </div>
    <div class="index-img">
      <img src="@//img/index06.jpg" alt="" />
    </div>
    <div
      style="
        background-color: #f8f8f8;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
      "
    >
      <img src="@//img/gsimg.jpg" />
      <div class="gongshijies">
        <!-- style="position: relative; left: -50px; z-index: 3" -->
        <!-- <img src="@//img/gongshi-img.png" alt="" /> -->
        <div style="font-size: 30px; color: #333">公司介绍</div>
        <p>丰合盈科技创世至今，以雄厚的技术团队和专业的设计团队，</p>
        <p>帮助中小企业快速搭建移动互联网平台</p>
        <p>
          公司自立于互联网的大环境下，致力于拓新型营销模式，用心打造值得信赖的多元化移动互联网
        </p>
        <div class="gongshi-but">查看更多</div>
      </div>
    </div>
    <div class="index-map">
      <div class="index-mine">
        <div class="titlemine">联系我们</div>
        <p>
          公司地址：中国(福建)自由贸易试验区厦门片区象屿路97号厦门国际航运中心D栋8层03单元A之十
        </p>
        <p>联系电话: 059 - 25599532</p>
      </div>
      <!-- <img src="@//img/index-map.png" alt="" /> -->
       <div style="padding:80px"><img src="@//img/baidumap.png"  /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      caseList: [
        {
          id: 1,
          img: "https://jmy-pic.baidu.com/0/pic/-1682508610_1831659289_-461867013.jpg",
          name: "会员卡",
          url: "",
        },
        {
          id: 2,
          img: "https://jmy-pic.baidu.com/0/pic/-748637759_1995525431_-1258052038.jpg",
          name: "儿童摄影",
          url: "",
        },
        {
          id: 3,
          img: "https://jmy-pic.baidu.com/0/pic/-1859313714_-2046192549_1191619455.jpg",
          name: "订水小程序",
          url: "",
        },
        {
          id: 4,
          img: "https://jmy-pic.baidu.com/0/pic/-2070472637_271475017_-41736474.jpg",
          name: "答题小程序",
          url: "",
        },
        {
          id: 5,
          img: "https://jmy-pic.baidu.com/0/pic/-644135818_-1580053162_348160473.jpg",
          name: "停车小程序",
          url: "",
        },
        {
          id: 6,
          img: "https://jmy-pic.baidu.com/0/pic/-70833798_-1063256635_2125390884.png",
          name: "办案日历",
          url: "",
        },
        {
          id: 7,
          img: "https://jmy-pic.baidu.com/0/pic/-693753295_-297169491_-1541626210.jpg",
          name: "金钻房产",
          url: "",
        },
        {
          id: 8,
          img: "https://jmy-pic.baidu.com/0/pic/-1393265073_-1391823459_1389110640.jpg",
          name: "马蜂窝自由行",
          url: "",
        },
      ],
    };
  },
};
</script>
<style scoped>
.title-name {
  font-size: 30px;
  color: #333;
  font-weight: 500;
}
.index-box {
  width: 80%;
}
.index-img img {
  width: 100%;
}
.index-from-tob {
  margin: 40px;
  display: flex;
  flex-direction: column;
  align-items: calc();
}
.index-tab {
  display: flex;
  justify-content: center;
  color: #999;
  font-size: 16px;
  margin: 30px;
}
.index-tab span {
  color: red;
}
.tab-item {
  text-align: left;
  margin: 5px;
}
.tab-item input {
  border: none;
  background: #f5f5f5;
  padding: 15px 15px;
  margin-top: 15px;
  width: 300px;
  color: #999;
  font-size: 14px;
}
.from-but {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button {
  width: 400px;
  padding: 10px;
  font-size: 20px;
  background: #3388ff;
  color: #fff;
}
.listCss {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}
.list-item {
  box-sizing: border-box;
  width: 23%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  padding: 25px;
  margin: 10px;
}
.item-img {
  width: 100%;
  height: 168px;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}
.item-img img {
  /* width: 100%; */
  height: 100%;
}
.item-name {
  font-size: 18px;
  color: #333;
  margin: 10px 0;
  text-align: left;
  font-weight: 500;
}
.item-type {
  width: 70px;
  font-size: 12px;
  background: #f2f7ff;
  color: #3388ff;
  padding: 5px 0px;
  margin-bottom: 40px;
}
.item-but {
  width: 100%;
  font-size: 20px;
  background: #f2f7ff;
  color: #3388ff;
  padding: 10px 0px;
  border-radius: 5px;
}
.index-map {
  display: flex;
  justify-content: space-between;
  background-image: url("@//img/map-bj.jpg");
  background-repeat: no-repeat;
  padding: 30px;

}
.index-mine {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  color: #fff;
  width: 40%;
  padding: 70px 0px 70px 70px;
  line-height: 30px;
}
.titlemine {
  font-size: 30px;
}
.gongshijies {
  width: 300px;
  text-align: left;
  background: #fff;
  position: relative;
  left: -50px;
  z-index: 3;
  padding: 30px;
  border-radius: 10px;
  color: #999;
}
.gongshi-but{
  width: 100px;
  background: #3388ff;
  color: #fff;
  padding: 10px;
  border-radius: 50px;
  text-align: center;
}
.gongshi-but:hover{
  color: #999;
}
</style>
