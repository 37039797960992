var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index-box"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',[_c('div',{staticClass:"title-name"},[_vm._v("案例介绍")]),_c('div',{staticClass:"listCss"},_vm._l((_vm.caseList),function(item){return _c('div',{key:item.id,staticClass:"list-item"},[_c('div',{staticClass:"item-img"},[_c('img',{attrs:{"src":item.img}})]),_c('div',{staticClass:"item-name"},[_c('span'),_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"item-type"},[_vm._v("小程序开发")]),_c('div',{staticClass:"item-but"},[_vm._v("立即联系")])])}),0)]),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index-img"},[_c('img',{attrs:{"src":require("@//img/index01.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index-from-tob"},[_c('div',{staticClass:"title-name"},[_vm._v("限时免费领取小程序开发报价")]),_c('div',{staticClass:"index-tab"},[_c('div',{staticClass:"tab-item"},[_c('div',[_vm._v("姓名 "),_c('span',[_vm._v("*")])]),_c('input',{attrs:{"type":"text","placeholder":"请留下您的姓名"}})]),_c('div',{staticClass:"tab-item"},[_c('div',[_vm._v("电话 "),_c('span',[_vm._v("*")])]),_c('input',{attrs:{"type":"text","placeholder":"请留下您的电话"}})])]),_c('div',{staticClass:"from-but"},[_c('div',{staticClass:"button"},[_vm._v("立即获取")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"title-name"},[_vm._v("产品中心")]),_c('div',{staticStyle:{"color":"#999","font-size":"14px","margin":"15px"}},[_vm._v(" 你可以及时了解公司以及公司产品的相关动态 ")]),_c('div',{staticClass:"index-img"},[_c('img',{staticStyle:{"width":"80%"},attrs:{"src":require("@//img/index07.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index-img"},[_c('img',{attrs:{"src":require("@//img/index02.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index-img"},[_c('img',{attrs:{"src":require("@//img/index03.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index-img"},[_c('img',{attrs:{"src":require("@//img/index04.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index-img"},[_c('img',{attrs:{"src":require("@//img/index05.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index-img"},[_c('img',{attrs:{"src":require("@//img/index06.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#f8f8f8","display":"flex","justify-content":"center","align-items":"center","padding":"20px 0"}},[_c('img',{attrs:{"src":require("@//img/gsimg.jpg")}}),_c('div',{staticClass:"gongshijies"},[_c('div',{staticStyle:{"font-size":"30px","color":"#333"}},[_vm._v("公司介绍")]),_c('p',[_vm._v("丰合盈科技创世至今，以雄厚的技术团队和专业的设计团队，")]),_c('p',[_vm._v("帮助中小企业快速搭建移动互联网平台")]),_c('p',[_vm._v(" 公司自立于互联网的大环境下，致力于拓新型营销模式，用心打造值得信赖的多元化移动互联网 ")]),_c('div',{staticClass:"gongshi-but"},[_vm._v("查看更多")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index-map"},[_c('div',{staticClass:"index-mine"},[_c('div',{staticClass:"titlemine"},[_vm._v("联系我们")]),_c('p',[_vm._v(" 公司地址：中国(福建)自由贸易试验区厦门片区象屿路97号厦门国际航运中心D栋8层03单元A之十 ")]),_c('p',[_vm._v("联系电话: 059 - 25599532")])]),_c('div',{staticStyle:{"padding":"80px"}},[_c('img',{attrs:{"src":require("@//img/baidumap.png")}})])])
}]

export { render, staticRenderFns }