<template>
  <div id="app">
    <top-menu></top-menu>
    <router-view></router-view>
    <!-- <div v-show="thisShow" class="uiShows">
      <div class="showflase">X</div>
      <div class="showimg"><img src="" alt=""></div>
      <div class="showtext">扫码获取方案报价</div>
    </div> -->
    <foot-text></foot-text>
  </div>
</template>

<script>
import TopMenu from "@/components/TopMenu.vue";
import FootText from "@/components/Foottext.vue";
export default {
  name: "App",
  components: {
    TopMenu,
    FootText,
  },
  data() {
    return {
      thisShow: true,
    };
  },
  methods: {
    uishow() {
      this.thisShow = false;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1600px;
  min-width: 1400px;
}
.uiShows {
  position: fixed;
  left: -1px;
  top: 50%;
  z-index: 100;
  width: 155px;
  height: 155px;
  border:1px #999 solid;
  display: flex;
  flex-direction:column;
}
.showflase {
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
  left: 135px;
  color: #999;
  width: 20px;
  height: 20px;
}
.showimg{
  width: 100px;
  height: 100px;
}
.showtext{

}
</style>
