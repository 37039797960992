<template>
  <div id="container"></div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  securityJsCode: "f4eb6ee914bbdf2fde05a43f376efd9f",
};
export default {
  data() {
    return {
      map: null,
      autoOptions: {
        input: "",
      },
      auto: null,
      placeSearch: null,
      searchPlaceInput: "",
      district: null,
      polygons: [],
      showHeatOrNot: false,
      heatmap: null,
      heatmapList: null,
    };
  },
  created() {
    //Search组件传输input的id
    bus.$on("share_id", (val) => {
      this.autoOptions.input = val;
    });
    //Search组件传输input输入框的值
    bus.$on("share", (val) => {
      this.searchPlaceInput = val;
    });
    // 热力图 组件传输input的ture
    bus.$on("shareHeatMapShow", (val) => {
      this.showHeatOrNot = val;
    });
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: "e060b842b746b3434ecb27f506cc5f43", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.ToolBar",
          "AMap.Scale", //比例尺
          "AMap.HawkEye",
          "AMap.MapType",
          "AMap.Geolocation",
          // "AMap.AutoComplete", //输入提示
          // "AMap.PlaceSearch", // 搜索功能
          // "AMap.DistrictSearch", //行政区边界绘制
        ], // 需要使用的的插件列表
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 10, //初始化地图级别
            center: [118.098991, 24518631], //初始化地图中心点位置
          });
          // this.map.addControl(new AMap.Scale()); //左下对方比例尺
          // this.map.addControl(new AMap.ToolBar()); //放大缩小
          // this.map.addControl(new AMap.MapType()); //地图类型
          // this.map.addControl(new AMap.HawkEye()); //鹰眼图
          // this.map.addControl(new AMap.Geolocation()); //获取自身位置
          // this.auto = new AMap.AutoComplete(this.autoOptions); //输入提示
          this.placeSearch = new AMap.PlaceSearch({
            map: this.map,
          }); //构造地点查询类
          this.auto.on("select", this.select);

          // 添加 固定点标记、
          let marker1 = new AMap.Marker({
            position: new AMap.LngLat(114.298572, 30.584355),
            title: "厦门市丰合盈科技有限公司",
          });
          //添加 点标记
          this.map.add(marker1);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    select(e) {
      this.placeSearch.setCity(e.poi.adcode);
      this.placeSearch.search(e.poi.name); //关键字查询查询
      this.map.setZoom(10, true, 1);
      this.drawBounds(e.poi.name); //事件
    },
    // 行政区区域划分
    drawBounds(newValue) {
      //加载行政区划插件
      if (!this.district) {
        //实例化DistrictSearch
        var opts = {
          subdistrict: 0, //获取边界不需要返回下级行政区
          extensions: "all", //返回行政区边界坐标组等具体信息
          level: "district", //查询行政级别为 市
        };

        this.map.plugin(["AMap.DistrictSearch"], () => {
          this.district = new AMap.DistrictSearch(opts);
        });
        // this.district = new AMap.DistrictSearch(opts)
      }
      //行政区查询
      this.district.search(newValue, (status, result) => {
        if (this.polygons != null) {
          this.map.remove(this.polygons); //清除上次结果
          this.polygons = [];
        }
        var bounds = result.districtList[0].boundaries;
        if (bounds) {
          for (var i = 0, l = bounds.length; i < l; i++) {
            //生成行政区划polygon
            var polygon = new AMap.Polygon({
              strokeWeight: 1,
              path: bounds[i],
              fillOpacity: 0.4,
              fillColor: "#80d8ff",
              strokeColor: "#0091ea",
            });
            this.polygons.push(polygon);
          }
        }
        this.map.add(this.polygons);
        this.map.setFitView(this.polygons); //视口自适应
      });
    },
    showHatChart(result) {
      // 到这里，我们就可以将需要的坐标加到热力图中让页面渲染
      // 其中的info是对热力图中的点进行处理，count是用于设置渲染强烈程度的
      var info = [];
      for (let i = 0; i < 50; i++) {
        info.push({
          lng: result.poiList.pois[i].location.lng,
          lat: result.poiList.pois[i].location.lat,
          count: 3 * 6.4 * Math.round(Math.random() * (10 - 2) + 2),
        });
      }

      this.map.plugin(["AMap.HeatMap"], () => {
        // console.log('nn')
        //初始化heatmap对象
        this.heatmap = new AMap.HeatMap(this.map, {
          radius: 56, //给定半径
          opacity: [0, 0.5],
          /*,
            gradient:{
                0.5: 'blue',
                0.65: 'rgb(117,211,248)',
                0.7: 'rgb(0, 255, 0)',
                0.9: '#ffea00',
                1.0: 'red'
            }
             */
        });
        //设置数据集
        this.heatmap.setDataSet({
          data: info,
          max: 100,
        });
        this.heatmapList.push(this.heatmap);
        this.heatmap.show();
      });
    },

    getHotChartPos(detail, result) {
      //由于高德实行分页所以我们需要在调用一个函数对目标进行实际的搜索获取每一个需要的对象的坐标
      let lengthSize = result.poiList.pageSize;
      const count = result.poiList.count;
      // const lengthPage = count / lengthSize
      if (lengthSize > count) {
        lengthSize = count;
      }
      for (var n = 0; n < 2; n++) {
        // this.map.plugin(['AMap.PlaceSearch'], () => {
        //构造地点查询类
        var realSearch = new AMap.PlaceSearch({
          pageSize: 50, // 单页显示结果条数
          pageIndex: n + 1, // 页码
          city: this.searchPlaceInput, // 兴趣点城市
          citylimit: true, //是否强制限制在设置的城市内搜索
          map: this.map, // 展现结果的地图实例
          panel: 'panel', // 结果列表将在此容器中进行展示。
          autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
        });
        realSearch.search(detail, (status, result) => {
          // for (var j = 0; j < 50; j++) {
          // this.map.remove(this.map.getAllOverlays('marker'))
          //var centerPoint = [result.poiList.pois[j].location.lng, result.poiList.pois[j].location.lat]
          // console.log(result)
          //热力图
          this.showHatChart(result);
          // }
        });
      }
    },

    showHeatMap() {
      this.map.plugin(["AMap.PlaceSearch"], () => {
        //构造地点查询类
        var placeSearch = new AMap.PlaceSearch({
          pageSize: 50, // 单页显示结果条数
          pageIndex: 1, // 页码
          city: this.searchPlaceInput, // 兴趣点城市
          citylimit: true, //是否强制限制在设置的城市内搜索
          map: this.map, // 展现结果的地图实例
          panel: 'panel', // 结果列表将在此容器中进行展示。
          autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
        });
        //关键字查询
        placeSearch.search("商场", (status, result) => {
          // console.log(result)

          this.getHotChartPos("商场", result);
        });
      });
      this.$notify({
        title: "成功",
        message: "热力图获取成果，但是由于电脑性能，我们仅加载部分数据",
        type: "success",
      });
    },
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
  },
  watch: {
    // 点击搜索按钮后传值的poi搜索
    searchPlaceInput(newValue) {
      if (newValue != null) {
        this.placeSearch.search(newValue); //当输入框有值的时候 进行搜索
        this.map.setZoom(16, true, 1); //地图级别
        this.drawBounds(newValue); //事件
      }
    },
    showHeatOrNot(newValue) {
      if (newValue) {
        this.showHeatMap();
      } else {
        this.heatmap.hide();
      }
    },
  },
};
</script>

<style>
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}
</style>

