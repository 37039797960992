
<template>
  <div class="about">
    <div class="title-name">公司介绍</div>
    <div class="about-con">
      <!-- <div class="about-text">{{ text }}}</div> -->
      <div class="about-text">
        厦门市丰合盈科技有限公司成立于2009年12月16日，注册地位于中国（福建）自由贸易试验区厦门片区象屿路97号厦门国际航运中心D栋8层03单元A之十，法定代表人为傅杰华。<br />
        经营范围包括一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；食用农产品批发；服装服饰批发；鞋帽批发；日用品销售；日用百货销售；家用电器销售；新材料技术推广服务；计算机软硬件及辅助设备批发；国内贸易代理；销售代理；进出口代理；通信设备销售；机械设备销售；货物进出口；珠宝首饰零售；信息咨询服务（不含许可类信息咨询服务）；安全咨询服务；社会经济咨询服务；企业管理；工程和技术研究和试验发展；互联网销售（除销售需要许可的商品）；建筑材料销售；轻质建筑材料销售；建筑防水卷材产品销售；建筑装饰材料销售；家具零配件销售；家具销售；五金产品零售；特种劳动防护用品销售；卫生洁具销售；卫生陶瓷制品销售；地板销售；配电开关控制设备销售；日用木制品销售。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）。许可项目：道路货物运输（不含危险货物）；电力设施承装、承修、承试；食品经营（销售散装食品）；食品经营；食品经营（销售预包装食品）；餐饮服务；婴幼儿配方乳粉销售；保健食品销售；住宿服务；互联网信息服务；第一类增值电信业务；第二类增值电信业务。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）。
      </div>

      <div class="about-img">
        <img src="@//img/adout-img.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      text: "厦门市丰合盈科技有限公司成立于2009年12月16日，注册地位于中国（福建）自由贸易试验区厦门片区象屿路97号厦门国际航运中心D栋8层03单元A之十，法定代表人为傅杰华。经营范围包括一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；食用农产品批发；服装服饰批发；鞋帽批发；日用品销售；日用百货销售；家用电器销售；新材料技术推广服务；计算机软硬件及辅助设备批发；国内贸易代理；销售代理；进出口代理；通信设备销售；机械设备销售；货物进出口；珠宝首饰零售；信息咨询服务（不含许可类信息咨询服务）；安全咨询服务；社会经济咨询服务；企业管理；工程和技术研究和试验发展；互联网销售（除销售需要许可的商品）；建筑材料销售；轻质建筑材料销售；建筑防水卷材产品销售；建筑装饰材料销售；家具零配件销售；家具销售；五金产品零售；特种劳动防护用品销售；卫生洁具销售；卫生陶瓷制品销售；地板销售；配电开关控制设备销售；日用木制品销售。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）。许可项目：道路货物运输（不含危险货物）；电力设施承装、承修、承试；食品经营（销售散装食品）；食品经营；食品经营（销售预包装食品）；餐饮服务；婴幼儿配方乳粉销售；保健食品销售；住宿服务；互联网信息服务；第一类增值电信业务；第二类增值电信业务。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）",
      // img: "",
    };
  },
};
</script>
<style scoped>
.about {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 30px 0;
}
.title-name {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}
.about-con {
  display: flex;
  align-items: flex-end;
}
.about-text {
  text-indent: 2em;
  color: #333;
  font-size: 16px;
  text-align: left;
  white-space: pre-wrap;
  flex: 1;
  line-height: 30px;
}
.about-img {
  flex: 1;
}
.about-img img {
  width: 80%;
}
</style>
