
<template>
  <div class="footBox">
    <!-- 安全保障 -->
    <div class="foot-top display-view">
      <div v-for="item in footList" :key="item.id">
        <div class="foot-top-item display-view">
          <img :src="item.icon" />
          <div class="foot-top-text">
            <span class="foot-top-text-title">{{ item.title }}</span>
            <span>{{ item.text }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="display-view foot-con">
      <div class="foot-con-item" v-for="(item,index) in menuList" :key="item.id">
        <div>
          {{index > 0 ? '|' : ''}}
        </div>
        <div class="foot-con-item-title">
          <router-link :to="{path:item.url}" style=" text-decoration: none;
  color: #333;">{{item.title}}</router-link>
        </div>
        <!-- {{ item.title }} -->
       
      </div>
    </div>
    <div class="foot-bot">
      厦门市丰合盈科技有限公司 地址:
      中国(福建)自由贸易试验区厦门片区象屿路97号厦门国际航运中心D栋8层03单元A之十 
      <a href="https://beian.miit.gov.cn  ">闽ICP备2020018624号</a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      footList: [
        {
          id: 0,
          icon: require("@//img/01.png"),
          title: "协助维权",
          text: "蒙受经济损失，可申请百度协助",
          url: "",
        },
        {
          id: 1,
          icon: require("@//img/02.png"),
          title: "虚假赔偿",
          text: "遇到品牌或资质冒牌，可申请百度协助",
          url: "",
        },
        {
          id: 2,
          icon: require("@//img/03.png"),
          title: "欺诈赔偿",
          text: "遇到欺诈，经核查属实，可申请保障退还费用",
          url: "",
        },
      ],
      menuList: [
        {
          id: 0,
          title: "首页",
          url: "/",
        },
        {
          id: 1,
          title: "公司介绍",
          url: "/about",
        },
        {
          id: 3,
          title: "联系我们",
          url: "/contact",
        },
      ],
    };
  },
};
</script>
 <style  scoped>
.footBox {
  width: 99vw;
  background: #f8f8f8;
  padding: 40px 0 20px;
  box-sizing: border-box;
  margin-top: 30px;
}
.foot-top {
  justify-content: center;
}
.foot-top-item {
  align-items: center;
  justify-content: flex-start;
  margin-right: 80px;
  flex: 1;
}
.foot-top-item img {
  width: 50px;
  height: 50px;
}
.foot-top-text {
  display: flex;
  margin-left: 10px;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}
.foot-top-text span {
  font-size: 12px;
  color: #999;
}
.foot-con-item-title{
  padding: 0 50px;
  cursor: pointer;
  text-decoration: none;
  color: #333;

}
.foot-con-item-title:hover{
  color: #3388ff;
}
.foot-top-text-title {
  font-weight: 600;
  font-size: 16px !important;
  color: #333 !important;
}
.foot-con {
  justify-content: center;
  margin: 20px 200px;
}
.foot-con-item {
  font-size: 16px;
  display: flex;
}
.foot-bot {
  color: #333;
  font-size: 12px;
}
.display-view {
  display: flex;
}
</style>

