
import Vue from 'vue'
import Router from 'vue-router'
import index from '@/views/index.vue'
import About from '@/views/About.vue'
import Contact from '@/views/Contact.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      component: index
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    }
  ]
})
