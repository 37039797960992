
<template>
  <div class="contact">
    <div class="title-name">公司地址</div>
    <div class="contact-con">
      <div class="contact-img">
        <img src="@//img/baidumap.png" />
        <!-- <map-container></map-container> -->
      </div>
      <div class="contact-text">
        <div class="text-item">
          <span style="line-height: 40px;">公司地址:</span>
          <div>
            中国（福建）自由贸易试验区厦门片区象屿路97号厦门国际航运中心D栋8层03单元A之十
          </div>
        </div>
        <div class="text-item">
          <span style="line-height: 40px;">联系电话:</span>
          <div>059 - 25599532</div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
import MapContainer from "@/components/MapContainer.vue";
export default {
  components: { MapContainer,},
  name: "contact",
  data() {
    return {};
  },
};
</script>
<style scoped>
.contact {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 30px 0;
  
}
.title-name {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  
}
.contact-con {
  width: 80%;
  background: #f8f8f8;
  display: flex;
}
.contact-img {
  width: 100%;
  flex: 3;
}
/* .contact-img img {
  width: 100%;
} */
.contact-text {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 20px 0 40px;
}
.text-item {
  padding: 20px 0;
  color: 16px;
  color: #333;
  text-align: left;
}
.text-item span {
  color: #666;
  padding: 5px 0;
}
</style>
